import './App.css';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useState, useRef, Component } from 'react';
import Home from "./home"
import NavBar from "./navBar"



function App() {

  return (
    <>
      <NavBar />
      <Home />
    </>
  )
}

export default App

  