import './App.css';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Table from 'react-bootstrap/Table';
import CurrencyInput from 'react-currency-input-field';
import { formatValue } from 'react-currency-input-field';
import { useEffect } from 'react';
import React, {useRef, Component, PureComponent} from 'react';
import { useState } from 'react';
import Overlay from 'react-bootstrap/Overlay';

import PlusCircle from 'react-bootstrap-icons/dist/icons/plus-circle'
import Form from 'react-bootstrap/Form';
import Trash3 from 'react-bootstrap-icons/dist/icons/trash3'  
import { PieChart, Pie, Cell } from 'recharts';



function Home() {


  let [newMoney, setNewMoney] = useState('1000')  // Hook to update the 'how much money are you adding field'


  // Updating allocation and percent for main categories (equitiy, commodities etc)
  const [equityPercent, setEquityPercent] = useState('20');
  const [equityAllocation, setEquityAllocation] = useState('10000');
  const [fixedIncomePercent, setFixedIncomePercent] = useState('20');
  const [fixedIncomeAllocation, setFixedIncomeAllocation] = useState('10000');
  const [realEstatePercent, setRealEstatePercent] = useState('20');
  const [realEstateAllocation, setRealEstateAllocation] = useState('10000');
  const [commoditiesPercent, setCommoditiesPercent] = useState('20');
  const [commoditiesAllocation, setCommoditiesAllocation] = useState('10000');
  const [cashPercent, setCashPercent] = useState('20');
  const [cashAllocation, setCashAllocation] = useState('10000');


  // Calculates total percent and total of the main categories
  const totalAllocation = parseFloat(equityAllocation)+parseFloat(fixedIncomeAllocation)+parseFloat(realEstateAllocation)+parseFloat(commoditiesAllocation)+parseFloat(cashAllocation);
  const totalPercent = parseFloat(equityPercent)+parseFloat(fixedIncomePercent)+parseFloat(realEstatePercent)+parseFloat(commoditiesPercent)+parseFloat(cashPercent)+"%"

  const equityNewPercent = equityAllocation / totalAllocation;
  const fixedIncomeNewPercent = fixedIncomeAllocation / totalAllocation;
  const realEstateNewPercent = realEstateAllocation / totalAllocation;
  const commoditiesNewPercent = commoditiesAllocation / totalAllocation;
  const cashNewPercent = cashAllocation / totalAllocation;


  ////////////////////////////  SUB ROWS  ////////////////////////////


  // Sets Row data for EQUITY Asset Class
  const [rowDataEquity, setRowDataEquity] = useState([]);
  const [rowDataFixedIncome, setRowDataFixedIncome] = useState([]);
  const [rowDataRealEstate, setRowDataRealEstate] = useState([]);
  const [rowDataCommodities, setRowDataCommodities] = useState([]);



  //Creates the new row
  const addRow = (masterRow) => {
    let newRow;
    if (masterRow === 'equityRow') {
      newRow = {
        id: rowDataEquity.length + 1,
        subAssetClass: "Equity ETF",
        subTargetAlloc: 25,
        subCurrentAmount: 2500,
        subAllocation: "0",
        subAmount: "0",
        subTotal: 2500,
        chart: true,
      };
      setRowDataEquity((prevRowData) => [...prevRowData, newRow]);
    } else if (masterRow === 'fixedIncomeRow') {
      newRow = {
        id: rowDataFixedIncome.length + 1,
        subAssetClass: "Bond ETF",
        subTargetAlloc: 25,
        subCurrentAmount: 2500,
        subAllocation: "0",
        subAmount: "0",
        subTotal: 2500,
        chart: true,
      };
      setRowDataFixedIncome((prevRowData) => [...prevRowData, newRow]);
    } else if (masterRow === 'realEstateRow') {
      newRow = {
        id: rowDataRealEstate.length + 1,
        subAssetClass: "REIT",
        subTargetAlloc: 25,
        subCurrentAmount: 2500,
        subAllocation: "0",
        subAmount: "0",
        subTotal: "0",
        chart: true,
      };
      setRowDataRealEstate((prevRowData) => [...prevRowData, newRow]);
    } else if (masterRow === 'commoditiesRow') {
      newRow = {
        id: rowDataCommodities.length + 1,
        subAssetClass: "Commodity ETF",
        subTargetAlloc: 25,
        subCurrentAmount: 2500,
        subAllocation: "0",
        subAmount: "0",
        subTotal: 2500,
        chart: true,
      };
      setRowDataCommodities((prevRowData) => [...prevRowData, newRow]);
    }
  };

  //Hook to handle the input change for CURRENCYINPUT forms
  const handleInputChange = (rowIndex, field, value, masterRow) => {
    if (masterRow === 'equityRow') {
      setRowDataEquity((prevRowData) =>
        prevRowData.map((row, index) =>
          index === rowIndex ? { 
            ...row, 
            [field]: value,
          } : row
      )
    )} else if (masterRow ==='fixedIncomeRow') {
      setRowDataFixedIncome((prevRowData) =>
      prevRowData.map((row, index) =>
        index === rowIndex ? { ...row, [field]: value } : row
      )
    )} else if (masterRow ==='realEstateRow') {
      setRowDataRealEstate((prevRowData) =>
      prevRowData.map((row, index) =>
        index === rowIndex ? { ...row, [field]: value } : row
      )
    )} else if (masterRow ==='commoditiesRow') {
      setRowDataCommodities((prevRowData) =>
      prevRowData.map((row, index) =>
        index === rowIndex ? { ...row, [field]: value } : row
      )
    )}
  };


  const handleInputChangeFormControl = (rowIndex, field, event, masterRow) => {

    if (masterRow === 'equityRow') {
      const value = event.target.value;
      setRowDataEquity((prevRowData) =>
        prevRowData.map((row, index) =>
          index === rowIndex 
            ? { 
              ...row,
              [field]: value,
              } 
            : row
        )
      );
    } else if (masterRow === 'fixedIncomeRow') {
      const value = event.target.value;

      setRowDataFixedIncome((prevRowData) =>
        prevRowData.map((row, index) =>
          index === rowIndex ? { ...row, [field]: value } : row
        )
      );
    } else if (masterRow === 'realEstateRow') {
      const value = event.target.value;

      setRowDataRealEstate((prevRowData) =>
        prevRowData.map((row, index) =>
          index === rowIndex ? { ...row, [field]: value } : row
        )
      );
    } else if (masterRow === 'commoditiesRow') {
      const value = event.target.value;

      setRowDataCommodities((prevRowData) =>
        prevRowData.map((row, index) =>
          index === rowIndex ? { ...row, [field]: value } : row
        )
      );
    }
  };

  
  // Sums together all subTargetAlloc for all subRows
  const totalSubEquityPercent = rowDataEquity.reduce((acc, currentObject) => acc + parseFloat(currentObject.subTargetAlloc), 0);
  const totalSubFixedIncomePercent = rowDataFixedIncome.reduce((acc, currentObject) => acc + parseFloat(currentObject.subTargetAlloc), 0);
  const totalSubRealEstatePercent = rowDataRealEstate.reduce((acc, currentObject) => acc + parseFloat(currentObject.subTargetAlloc), 0);
  const totalSubCommoditiesPercent = rowDataCommodities.reduce((acc, currentObject) => acc + parseFloat(currentObject.subTargetAlloc), 0);




  //FLAG for whether the sum of equity rows is greater than 100
  let isSubEquityOneHundered = false;
  if (totalSubEquityPercent <= 100) {
    isSubEquityOneHundered = false;
    } else {
    isSubEquityOneHundered = true;
  };
  let isSubFixedIncomeOneHundered = false;
  if (totalSubFixedIncomePercent <= 100) {
    isSubFixedIncomeOneHundered = false;
  } else {
    isSubFixedIncomeOneHundered = true;
  };
  let isSubRealEstateOneHundered = false;
  if (totalSubRealEstatePercent <= 100) {
    isSubRealEstateOneHundered = false;
  } else {
    isSubRealEstateOneHundered = true;
  };
  let isSubCommoditiesOneHundered = false;
  if (totalSubCommoditiesPercent <= 100) {
    isSubCommoditiesOneHundered = false;
  } else {
    isSubCommoditiesOneHundered = true;
  };

  //MAPS subRows to delete
  const deleteRow = (index, masterRow) => {
    if (masterRow === "equityRow") {
      setRowDataEquity((prevRowData) => prevRowData.filter((_, i) => i !== index));
    } else if (masterRow === "fixedIncomeRow") {
      setRowDataFixedIncome((prevRowData) => prevRowData.filter((_, i) => i !== index));
    }  else if (masterRow === "realEstateRow") {
      setRowDataRealEstate((prevRowData) => prevRowData.filter((_, i) => i !== index));
    }  else if (masterRow === "commoditiesRow") {
      setRowDataCommodities((prevRowData) => prevRowData.filter((_, i) => i !== index));
    } 
  };

  let eAllocStatus = false
  if (rowDataEquity.length >= 1) {
    eAllocStatus = true
  }
  let fAllocStatus = false
  if (rowDataFixedIncome.length >= 1) {
    fAllocStatus = true
  }
  let rAllocStatus = false
  if (rowDataRealEstate.length >= 1) {
    rAllocStatus = true
  }
  let cAllocStatus = false
  if (rowDataCommodities.length >= 1) {
    cAllocStatus = true
  }

  const targetTotal = useRef(null);
  const targetSubAlloc = useRef(null)
  const targetSubFixedIncome = useRef(null)
  const targetSubRealEstate = useRef(null)
  const targetSubCommodities = useRef(null)


  ////////////////////////////  MAIN ROWS  ////////////////////////////


  let equityBuySell = null
  if (totalPercent === "100%") {
    equityBuySell = (totalAllocation + parseFloat(newMoney)) * (equityPercent/100) - equityAllocation
    } else {
    equityBuySell = "-"
    }
  
  let fixedIncomeBuySell = (totalAllocation + parseFloat(newMoney)) * (fixedIncomePercent/100) - fixedIncomeAllocation
  if (totalPercent === "100%") {
    fixedIncomeBuySell = (totalAllocation + parseFloat(newMoney)) * (fixedIncomePercent/100) - fixedIncomeAllocation
    } else {
    fixedIncomeBuySell = "-"
    }
  
  let realEstateBuySell = (totalAllocation + parseFloat(newMoney)) * (realEstatePercent/100) - realEstateAllocation
  if (totalPercent === "100%") {
    realEstateBuySell = (totalAllocation + parseFloat(newMoney)) * (realEstatePercent/100) - realEstateAllocation
    } else {
    realEstateBuySell = "-"
    }

  let commoditiesBuySell = (totalAllocation + parseFloat(newMoney)) * (commoditiesPercent/100) - commoditiesAllocation
  if (totalPercent === "100%") {
    commoditiesBuySell = (totalAllocation + parseFloat(newMoney)) * (commoditiesPercent/100) - commoditiesAllocation
    } else {
    commoditiesBuySell = "-"
    }

  let cashBuySell = (totalAllocation + parseFloat(newMoney)) * (cashPercent/100) - cashAllocation
  if (totalPercent === "100%") {
    cashBuySell = (totalAllocation + parseFloat(newMoney)) * (cashPercent/100) - cashAllocation
    } else {
    cashBuySell = "-"
    }
 
  let equityNewTotal = parseFloat(equityAllocation) + parseFloat(equityBuySell)
  if (totalPercent === "100%") {
    equityNewTotal = parseFloat(equityAllocation) + parseFloat(equityBuySell)
    } else {
      equityNewTotal = "-"
    }

  let fixedIncomeNewTotal = parseFloat(fixedIncomeAllocation) + parseFloat(fixedIncomeBuySell)
  if (totalPercent === "100%") {
    fixedIncomeNewTotal = parseFloat(fixedIncomeAllocation) + parseFloat(fixedIncomeBuySell)
    } else {
      fixedIncomeNewTotal = "-"
    }

  let realEstateNewTotal = parseFloat(realEstateAllocation) + parseFloat(realEstateBuySell)
  if (totalPercent === "100%") {
    realEstateNewTotal = parseFloat(realEstateAllocation) + parseFloat(realEstateBuySell)
    } else {
      realEstateNewTotal = "-"
    }

  let commoditiesNewTotal = parseFloat(commoditiesAllocation) + parseFloat(commoditiesBuySell)
  if (totalPercent === "100%") {
    commoditiesNewTotal = parseFloat(commoditiesAllocation) + parseFloat(commoditiesBuySell)
    } else {
      commoditiesNewTotal = "-"
    }

  let cashNewTotal = parseFloat(cashAllocation) + parseFloat(cashBuySell)
  if (totalPercent === "100%") {
    cashNewTotal = parseFloat(cashAllocation) + parseFloat(cashBuySell)
    } else {
      cashNewTotal = "-"
    }



    //ERROR FLAG

  let percentStyle = {
    color: "white",
    backgroundColor: 'rgba(255, 100, 100, 0.85)',
    borderRadius: "10px 10px 0px 0px"
  };

  const subRowStyle = (masterRow) => {
    if (masterRow === "equityRow") {
      if (isSubEquityOneHundered === true) {
        return "form-control subForm-control-sm text-center subForm-Error";
      } else {
        return "form-control subForm-control-sm text-center";
      }
    } else if (masterRow === "fixedIncomeRow") {
      if (isSubFixedIncomeOneHundered === true) {
        return "form-control subForm-control-sm text-center subForm-Error";
      } else {
        return "form-control subForm-control-sm text-center";
      };
    } else if (masterRow === "realEstateRow") {
      if (isSubRealEstateOneHundered === true) {
        return "form-control subForm-control-sm text-center subForm-Error";
      } else {
        return "form-control subForm-control-sm text-center";
      };
    } else if (masterRow === "commoditiesRow") {
      if (isSubCommoditiesOneHundered === true) {
        return "form-control subForm-control-sm text-center subForm-Error";
      } else {
        return "form-control subForm-control-sm text-center";
      };
    };
  };
  

  let isOneHundered = true;
  if (totalPercent === "100%") {
    percentStyle = {
      color: "black",
      fontWeight: "bold"
    }
    isOneHundered = false;
  } else {
    isOneHundered = true;
  };


  //CHARTS



  const consolidatedData = [
    ...(rowDataEquity.length > 0 ? rowDataEquity : [{ subCurrentAmount: equityAllocation }]),
    ...(rowDataFixedIncome.length > 0 ? rowDataFixedIncome : [{ subCurrentAmount: fixedIncomeAllocation }]),
    ...(rowDataRealEstate.length > 0 ? rowDataRealEstate : [{ subCurrentAmount: realEstateAllocation }]),
    ...(rowDataCommodities.length > 0 ? rowDataCommodities : [{ subCurrentAmount: commoditiesAllocation }]),
    ...([{subCurrentAmount: cashAllocation}]),
  ];


  //Creates another array that's used to display the Pie Chart
  const currentSubSeries = consolidatedData.map((row) => ({
    name: row.subAssetClass || 'Master Row', // Adjust the property based on your object structure
    value: parseFloat(row.subCurrentAmount),
    chart: row.chart
  }));

  const remainderValuesEquity = {
    subAssetClass: "remainder",
    subTotal: equityNewTotal - sumSubNewTotal(rowDataEquity),
    chart: false,
  };

  const remainderValuesFixedIncome = {
    subAssetClass: 'Remainder Fixed Income',
    subTotal: fixedIncomeNewTotal - sumSubNewTotal(rowDataFixedIncome),
    chart: false,
  };
  
  const remainderValuesRealEstate = {
    subAssetClass: 'Remainder Real Estate',
    subTotal: realEstateNewTotal - sumSubNewTotal(rowDataRealEstate),
    chart: false,
  };
  
  const remainderValuesCommodities = {
    subAssetClass: 'Remainder Commodities',
    subTotal: commoditiesNewTotal - sumSubNewTotal(rowDataCommodities),
    chart: false,
  };
  
  const remainderValuesCash = {
    subAssetClass: 'Remainder Cash',
    subTotal: cashAllocation - sumSubNewTotal(rowDataCommodities), // Assuming cashAllocation is not an array
    chart: false,
  };
  

/* 
  newRow = {
    id: rowDataEquity.length + 1,
    subAssetClass: "enter fund name",
    subTargetAlloc: 25,
    subCurrentAmount: 2500,
    subAllocation: "0",
    subAmount: "0",
    subTotal: 2500,
    chart: true,
  } */

  //Creates another array that's used to display the Pie Chart
  const newConsolidatedData = [
    ...(rowDataEquity.length > 0 ? rowDataEquity : [{ subAssetClass: 'Master Row', subTotal: equityNewTotal, chart: false }]),
    ...(rowDataEquity.length > 0 ? [remainderValuesEquity] : [] ),
    ...(rowDataFixedIncome.length > 0 ? rowDataFixedIncome : [{ subAssetClass: 'why ?', subTotal: fixedIncomeNewTotal, chart: false }]),
    ...(rowDataFixedIncome.length > 0 ? [remainderValuesFixedIncome] : [] ),
    ...(rowDataRealEstate.length > 0 ? rowDataRealEstate : [{ subAssetClass: 'Master Row', subTotal: realEstateNewTotal, chart: false }]),
    ...(rowDataRealEstate.length > 0 ? [remainderValuesRealEstate] : [] ),
    ...(rowDataCommodities.length > 0 ? rowDataCommodities : [{ subAssetClass: 'Master Row', subTotal: commoditiesNewTotal, chart: false }]),
    ...(rowDataCommodities.length > 0 ? [remainderValuesCommodities] : [] ),
    ...([{ subAssetClass: 'Master Row', subTotal: cashNewTotal, chart: false }]),
  ];

  const newSubSeries = newConsolidatedData.map((row) => ({
    name: row.subAssetClass,
    value: parseFloat(row.subTotal),
    chart: row.chart,
  }));
  

  const currentSeries = [
    ...(parseFloat(equityAllocation) !== 0 ? [{ name: "Equity", value: Math.trunc(parseFloat(equityAllocation)) }] : []),
    ...(parseFloat(fixedIncomeAllocation) !== 0 ? [{ name: "Fixed Income", value: Math.trunc(parseFloat(fixedIncomeAllocation)) }] : []),
    ...(parseFloat(realEstateAllocation) !== 0 ? [{ name: "RealEstate", value: Math.trunc(parseFloat(realEstateAllocation)) }] : []),
    ...(parseFloat(commoditiesAllocation) !== 0 ? [{ name: "Commodities", value: Math.trunc(parseFloat(commoditiesAllocation)) }] : []),
    ...(parseFloat(cashAllocation) !== 0 ? [{ name: "Cash", value: Math.trunc(parseFloat(cashAllocation)) }] : []),
  ];

  const masterRowColor = 'rgba(0, 0, 0, 0)';
  const nonMasterRowColor = '#82ca9d'; 


  const newSeries = totalPercent !== "100%" 
    ? [ {name: "Total Percent must equall 100%", value: 1} ]
    : [
    ...(parseFloat(equityNewTotal) !== 0 ? [{ name: "Equity", value: Math.trunc(parseFloat(equityNewTotal)) }] : []),
    ...(parseFloat(fixedIncomeNewTotal) !== 0 ? [{ name: "Fixed Income", value: Math.trunc(parseFloat(fixedIncomeNewTotal)) }] : []),
    ...(parseFloat(realEstateNewTotal) !== 0 ? [{ name: "Real Estate", value: Math.trunc(parseFloat(realEstateNewTotal)) }] : []),
    ...(parseFloat(commoditiesNewTotal) !== 0 ? [{ name: "Commodities", value: Math.trunc(parseFloat(commoditiesNewTotal)) }] : []),
    ...(parseFloat(cashNewTotal) !== 0 ? [{ name: "Cash", value: Math.trunc(parseFloat(cashNewTotal)) }] : []),
  ];


  // The remainder values so that when the chart displays the subSeries pie chart aligns correctly if subValues dont total 100%



  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel_currentSeriesInner = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const labelOld = currentSeries[index].name;
    const labelValueOld =  formatValue({prefix: "$", decimalScale: 0, value: String(currentSeries[index].value)});
  
    return (
      <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
        <tspan>{labelOld}</tspan>
        <tspan x={x} dy="1.2em" dominantBaseline="central">
          {labelValueOld}
        </tspan>
      </text>
    );
  };

  const renderCustomizedLabel_currentSeriesInnerNew = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = totalPercent === "100%" ? cx + radius * Math.cos(-midAngle * RADIAN) : cx + radius * Math.cos(-midAngle * RADIAN) + 80;
    const y = totalPercent === "100%" ? cy + radius * Math.sin(-midAngle * RADIAN) : cy + radius * Math.sin(-midAngle * RADIAN) - 50;
    const labelNew = totalPercent !== "100%" ? "Total Percent Must Equal 100" : newSeries[index].name;
    const labelValueNew = totalPercent === "100%" ? formatValue({prefix: "$", decimalScale: 0, value: String(newSeries[index].value)}) : '';

      
  
    return (
      <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
        <tspan>{labelNew}</tspan>
        <tspan x={x} dy="1.2em" dominantBaseline="central">
          {labelValueNew}
        </tspan>
      </text>
    );
  };

  

  const labelSubOld = (index) => {
    return currentSubSeries[index].name;
  }

  const labelSubNew = (index) => {
    return newSubSeries[index].name;
  }

  const labelSubValueOld = (index) => {
    return formatValue({prefix: "$", decimalScale: 0, value: String(currentSubSeries[index].value)});
  }

  const labelSubValueNew = (index) => {
    return formatValue({prefix: "$", decimalScale: 0, value: String(newSubSeries[index].value)});
  }


  const renderCustomizedLabel_currentSeriesOuter = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text 
        x={x} 
        y={y} 
        fill={payload.chart ? "rgba(0, 0, 0, 1)" : "rgba(0,0,0,0)"}
        textAnchor={x > cx ? 'start' : 'end'} 
        dominantBaseline="central" >
        {labelSubOld(index)}: {labelSubValueOld(index)}
      </text>
    );
  };

  const renderCustomizedLabel_newSeriesOuter = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text 
        x={x} 
        y={y} 
        fill={payload.chart ? "rgba(0, 0, 0, 1)" : "rgba(0,0,0,0)"}
        textAnchor={x > cx ? 'start' : 'end'} 
        dominantBaseline="central" >
        {labelSubNew(index)}: {labelSubValueNew(index)}
      </text>
    );
  };


  //POST LOAD STATE CHANGES


  function sumSubCurrentAmount(array) {
    return array.reduce((sum, item) => sum + parseFloat(item.subCurrentAmount || 0), 0);
  }

  function sumSubNewTotal(array) {
    return array.reduce((sum, item) => sum + parseFloat(item.subTotal || 0), 0);
  }


  useEffect(() => {


    if (eAllocStatus) {
      setEquityAllocation(sumSubCurrentAmount(rowDataEquity)); 
      setRowDataEquity((prevRowData) => {
        const updatedRowDataEquity = prevRowData.map((row) => {
          const subAmount = totalPercent === '100%'
            ? (totalAllocation + parseFloat(newMoney)) * ((parseFloat(row.subTargetAlloc) / 100) * (parseFloat(equityPercent) / 100)) - parseFloat(row.subCurrentAmount)
            : '-';
          
          const subTotal = totalPercent === '100%'
            ? parseFloat(subAmount) + parseFloat(row.subCurrentAmount)
            : '-';
    
          return {
            ...row,
            subAllocation: row.subCurrentAmount / sumSubCurrentAmount(rowDataEquity),
            subAmount,
            subTotal,
          };
        });

        return updatedRowDataEquity;
      });
    } 

    if (fAllocStatus) {
      setFixedIncomeAllocation(sumSubCurrentAmount(rowDataFixedIncome))
      setRowDataFixedIncome((prevRowData) => {
        const updatedRowDataFixedIncome = prevRowData.map((row) => {
          const subAmount = totalPercent === '100%'
            ? (totalAllocation + parseFloat(newMoney)) * ((parseFloat(row.subTargetAlloc) / 100) * (parseFloat(fixedIncomePercent) / 100)) - parseFloat(row.subCurrentAmount)
            : '-';
          
          const subTotal = totalPercent === '100%'
            ? parseFloat(subAmount) + parseFloat(row.subCurrentAmount)
            : '-';
    
          return {
            ...row,
            subAllocation: row.subCurrentAmount / sumSubCurrentAmount(rowDataFixedIncome),
            subAmount,
            subTotal,
          };
        });

        return updatedRowDataFixedIncome;
      });
    } 


    if (rAllocStatus) {
      setRealEstateAllocation(sumSubCurrentAmount(rowDataRealEstate))
      setRowDataRealEstate((prevRowData) => {
        const updateRowDataRealEstate = prevRowData.map((row) => {
          const subAmount = totalPercent === '100%'
            ? (totalAllocation + parseFloat(newMoney)) * ((parseFloat(row.subTargetAlloc) / 100) * (parseFloat(realEstatePercent) / 100)) - parseFloat(row.subCurrentAmount)
            : '-';
          
          const subTotal = totalPercent === '100%'
            ? parseFloat(subAmount) + parseFloat(row.subCurrentAmount)
            : '-';
    
          return {
            ...row,
            subAllocation: row.subCurrentAmount / sumSubCurrentAmount(rowDataRealEstate),
            subAmount,
            subTotal,
          };
        });

        return updateRowDataRealEstate;
      });
    } 

    if (cAllocStatus) {
      setCommoditiesAllocation(sumSubCurrentAmount(rowDataCommodities))
      setRowDataCommodities((prevRowData) => {
        const updatedRowDataCommodities = prevRowData.map((row) => {
          const subAmount = totalPercent === '100%'
            ? (totalAllocation + parseFloat(newMoney)) * ((parseFloat(row.subTargetAlloc) / 100) * (parseFloat(commoditiesPercent) / 100)) - parseFloat(row.subCurrentAmount)
            : '-';
          
          const subTotal = totalPercent === '100%'
            ? parseFloat(subAmount) + parseFloat(row.subCurrentAmount)
            : '-';
    
          return {
            ...row,
            subAllocation: row.subCurrentAmount / sumSubCurrentAmount(rowDataCommodities),
            subAmount,
            subTotal,
          };
        });

        return updatedRowDataCommodities;
      });
    } 
 
  }, [eAllocStatus, fAllocStatus, rAllocStatus, cAllocStatus, rowDataEquity, rowDataFixedIncome, rowDataRealEstate, rowDataCommodities, totalAllocation, newMoney, equityPercent]);


  


  const renderRows = (masterRow) => {
    const stateSetterMap = {
      'equityRow': rowDataEquity,
      'fixedIncomeRow': rowDataFixedIncome,
      'realEstateRow' : rowDataRealEstate,
      'commoditiesRow' : rowDataCommodities,
    };
  
    const stateSetter = stateSetterMap[masterRow];

    return (
      stateSetter.map((row, index) => (
        <tr key={row.id}>
          <td>
            <span className="d-flex justify-content-end">
            <button type = "button" className="btn btn-sm btn-primary subButton" onClick={() => deleteRow(index, masterRow)}><Trash3 /></button>
              <Form.Control
                type="text" 
                placeholder={row.subAssetClass} 
                className="subForm-control-sm subForm-control-centred"
                onChange={(event) => {
                  handleInputChangeFormControl(index, 'subAssetClass', event, masterRow);
                }}
              /> 
            </span>
          </td>
          <td>
            <span className="d-flex justify-content-end">
              <CurrencyInput              
                allowDecimals={false}
                suffix={"%"}
                onValueChange={(value) => {
                  console.log('Value changed:', value);
                  handleInputChange(index, 'subTargetAlloc', value, masterRow);
                }}
                step={1}
                className={subRowStyle(masterRow)}
                value={row.subTargetAlloc}
              />
            </span>
          </td>
          <td>
            <span className="d-flex justify-content-end">
              <CurrencyInput              
                allowDecimals={false}
                prefix={"$"}
                onValueChange={(value) => {
                  console.log('Value changed:', value);
                  handleInputChange(index, 'subCurrentAmount', value, masterRow)
                }}
                step={1}
                className = "form-control subForm-control-sm text-center"
                value={row.subCurrentAmount}
              /> 
            </span>
          </td>
          <td className = "fw-light small subAllocation">{Math.ceil(row.subAllocation*100) + "%"}</td>
          <td className = "fw-light small">{formatValue({ prefix: "$", decimalScale: 0, value: String(row.subAmount) })}</td>
          <td className = "fw-light small">{formatValue({ prefix: "$", decimalScale: 0, value: String(row.subTotal) })}</td>
        </tr>
      ))
    );

  };

  return (
    
    <div className="portfolioRebalancer">
      <div className ="header">
        <h1 className = "display-4">
          Portfolio Rebalancing Calculator
        </h1>
        <h4 >
          <small class="text-muted">Calculate how much of an asset you need to buy or sell to achieve a target portfolio allocation</small>
        </h4>
      </div>
      <div className="chart">
        <div className="allocationChart">
          <PieChart width={630} height={500}>
            <Pie
              data={currentSeries} 
              dataKey="value" 
              cx="50%" 
              cy="50%" 
              outerRadius={120} 
              fill="#8884d8" 
              label={renderCustomizedLabel_currentSeriesInner} 
              labelLine={false}
            />
            <Pie
              data={currentSubSeries} 
              dataKey="value" 
              cx="50%" 
              cy="50%" 
              innerRadius={130} 
              outerRadius={150} 
              fill="#82ca9d" 
              label={renderCustomizedLabel_currentSeriesOuter}
            >
              {currentSubSeries.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.chart ? nonMasterRowColor : masterRowColor}
                  stroke={entry.chart ? "white" : "transparent"}
                />
              ))}
            </Pie>
          </PieChart>
        </div>
        <div className="arrow">
          ➔
        </div>
        <div className="newAllocationChart">
          <PieChart width={630} height={500}>
            <Pie
              data={newSeries} 
              dataKey="value" 
              cx="50%" 
              cy="50%" 
              outerRadius={120} 
              fill= {totalPercent === "100%" ? "#8884d8" : "gray"}
              label={renderCustomizedLabel_currentSeriesInnerNew} 
              labelLine={false}
              stroke = {totalPercent === "100%" ? "white" : "rgba(0,0,0,0)"}
            />
            <Pie
              data={newSubSeries} 
              dataKey="value" 
              cx="50%" 
              cy="50%" 
              innerRadius={130} 
              outerRadius={150} 
              fill="#82ca9d" 
              label={renderCustomizedLabel_newSeriesOuter}
            >
              {newSubSeries.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.chart ? nonMasterRowColor : masterRowColor}
                  stroke={entry.chart ? "white" : "transparent"}
                />
              ))}
            </Pie>
          </PieChart>
        </div>
      </div>
      <div className="tableSizing table-responsive">
        <Table striped borderless >
          <thead className="tableHead">
            <tr className="centred">
              <td className="moneyAdd" colSpan={12}>
                <label for = "text" className= "h4 text-muted">How Much Money are you Adding:
                  <form className="moneyAddForm">
                    <CurrencyInput 
                      value={newMoney}
                      placeholder="$1,000"
                      allowDecimals={false}
                      prefix={"$"}
                      onValueChange={(value) => setNewMoney(value)}
                      className={"form-control form-control-sm text-center"}
                    />
                  </form>
                </label>
              </td>
            </tr>

{/* TABLE HEADER CATEGORIES */}

            <tr>
              <th>Asset Class</th>
              <th>Target Allocation</th>
              <th>Current Amount</th>
              <th className="allocation">Current Allocation</th>
              <th>Amount to Buy or Sell</th>
              <th>New Total</th>
            </tr>
          </thead>

{/* TABLE ROWS */}


      {/* EQUITY */}

          <tbody className="tableBody">
            <tr className="align-middle text-center">
              <td>
                <span className = "plus">
                  <button type = "button" className="btn btn-primary mainButton" onClick={() => addRow('equityRow')}><PlusCircle /></button>
                </span>
                <div className="masterRowName">
                  Equity
                </div>
              </td>
              <td className="percentInput">
                <div>
                  <CurrencyInput 
                    value={equityPercent}
                    placeholder="20%"
                    allowDecimals={false}
                    suffix={"%"}
                    onValueChange={(value) => setEquityPercent(value)}
                    step={1}
                    className={"form-control form-control-sm text-center"}
                  />
                </div>
              </td>
              <td className="dollarInput">
                <div>
                  <CurrencyInput 
                    value={equityAllocation}
                    placeholder="$1,000"
                    allowDecimals={false}
                    prefix={"$"}
                    onValueChange={(value) => setEquityAllocation(value)}
                    step={1}
                    className={"form-control form-control-sm text-center"}
                    disabled={eAllocStatus}
                  />
                </div>
              </td>
              <td className="allocation text-center">
                <div data-equityAllocation>
                  {formatValue({ suffix: "%", decimalScale: 0, value: String(equityNewPercent*100) })}
                </div>
              </td>
              <td>
                <div data-equityBuySell>
                  {formatValue({ prefix: "$", decimalScale: 0, value: String(equityBuySell) })}
                </div>
              </td>
              <td>
                <div data-equityNewTotal>
                  {formatValue({ prefix: "$", decimalScale: 0, value: String(equityNewTotal) })}
                </div>
              </td>
            </tr>

{/* //RENDER SUB ROWS */}

            {renderRows('equityRow')}

{/* //OVERLAY ERROR FLAG FOR IF VALUES ARE GREATER THAN 100 */}

            <td className="totalPercent" ref={targetSubAlloc} >
                <Overlay target={targetSubAlloc.current} show={isSubEquityOneHundered} placement="right">
                  {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                  }) => (
                    <div
                      {...props}
                      style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(255, 100, 100, 0.85)',
                        padding: '2px 10px',
                        color: 'white',
                        borderRadius: 3,
                        ...props.style,
                      }}
                    >
                      Funds that are part of the Equity basket shouldn't total more than 100%
                    </div>
                  )}
                </Overlay>
              </td>




      {/* FIXED INCOME */}

            <tr className="align-middle">
              <td>
                <span className = "plus">
                  <button type = "button" className="btn btn-primary mainButton" onClick={() => addRow('fixedIncomeRow')}><PlusCircle /></button>
                </span>
                <div className="masterRowName">
                  Fixed Income
                </div>
              </td>
              <td className="percentInput">
                <div>
                  <CurrencyInput 
                    value={fixedIncomePercent}
                    placeholder="20%"
                    allowDecimals={false}
                    suffix={"%"}
                    onValueChange={(value) => setFixedIncomePercent(value)}
                    step={1}
                    className={"form-control form-control-sm text-center"}
                  />
                </div>
              </td>
              <td>
                <div>
                  <CurrencyInput 
                    value={fixedIncomeAllocation}
                    placeholder="$1,000"
                    allowDecimals={false}
                    prefix={"$"}
                    onValueChange={(value) => setFixedIncomeAllocation(value)}
                    step={100}
                    className={"form-control form-control-sm text-center"}
                    disabled={fAllocStatus}
                  />
                </div>					
              </td>
              <td className="allocation">
                <div data-fixedIncomeAllocation>
                  {formatValue({ suffix: "%", decimalScale: 0, value: String(fixedIncomeNewPercent*100) })}
                </div>
              </td>
              <td>
                <div data-fixedIncomeBuySell>
                  {formatValue({ prefix: "$", decimalScale: 0, value: String(fixedIncomeBuySell) })}
                </div>
              </td>
              <td>
                <div data-fixedIncomeNewTotal>
                  {formatValue({ prefix: "$", decimalScale: 0, value: String(fixedIncomeNewTotal) })}
                </div>
              </td>
            </tr>

{/* //RENDER SUB ROWS */}

            {renderRows('fixedIncomeRow')}

{/* //OVERLAY ERROR FLAG FOR IF VALUES ARE GREATER THAN 100 */}

<td className="totalPercent" ref={targetSubFixedIncome} >
                <Overlay target={targetSubFixedIncome.current} show={isSubFixedIncomeOneHundered} placement="right">
                  {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                  }) => (
                    <div
                      {...props}
                      style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(255, 100, 100, 0.85)',
                        padding: '2px 10px',
                        color: 'white',
                        borderRadius: 3,
                        ...props.style,
                      }}
                    >
                      Funds that are part of the Fixed Income basket shouldn't total more than 100%
                    </div>
                  )}
                </Overlay>
              </td>





      {/* REAL ESTATE */}

            <tr className="align-middle">
              <td>
                <span className = "plus">
                  <button type = "button" className="btn btn-primary mainButton" onClick={() => addRow('realEstateRow')}><PlusCircle /></button>
                </span>
                <div className = "masterRowName">
                  Real Estate
                </div>
              </td>
              <td className="percentInput">
                <div>
                  <CurrencyInput 
                    value={realEstatePercent}
                    placeholder="20%"
                    allowDecimals={false}
                    suffix={"%"}
                    onValueChange={(value) => setRealEstatePercent(value)}
                    step={1}
                    className={"form-control form-control-sm text-center"}
                  />
                </div>
              </td>
              <td>
                <div>
                  <CurrencyInput 
                    value={realEstateAllocation}
                    placeholder="$1,000"
                    allowDecimals={false}
                    prefix={"$"}
                    onValueChange={(value) => setRealEstateAllocation(value)}
                    step={100}
                    className={"form-control form-control-sm text-center"}
                    disabled={rAllocStatus}
                  />
                </div>					
              </td>
              <td className="allocation">
                <div data-realEstateAllocation>
                  {formatValue({ suffix: "%", decimalScale: 0, value: String(realEstateNewPercent*100) })}
                </div>
              </td>
              <td>
                <div data-realEstateBuySell>
                  {formatValue({ prefix: "$", decimalScale: 0, value: String(realEstateBuySell) })}
                </div>
              </td>
              <td>
                <div data-realEstateNewTotal>
                  {formatValue({ prefix: "$", decimalScale: 0, value: String(realEstateNewTotal) })}
                </div>
              </td>
            </tr>


{/* //RENDER SUB ROWS */}

            {renderRows('realEstateRow')}

{/* //OVERLAY ERROR FLAG FOR IF VALUES ARE GREATER THAN 100 */}

<td className="totalPercent" ref={targetSubRealEstate} >
                <Overlay target={targetSubRealEstate.current} show={isSubRealEstateOneHundered} placement="right">
                  {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                  }) => (
                    <div
                      {...props}
                      style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(255, 100, 100, 0.85)',
                        padding: '2px 10px',
                        color: 'white',
                        borderRadius: 3,
                        ...props.style,
                      }}
                    >
                      Funds that are part of the Real Estate basket shouldn't total more than 100%
                    </div>
                  )}
                </Overlay>
              </td>



      {/* COMMODITIES */}

            <tr className="align-middle">
              <td>
                <span className = "plus">
                  <button type = "button" className="btn btn-primary mainButton" onClick={() => addRow('commoditiesRow')}><PlusCircle /></button>
                </span>
                <div className = "masterRowName">
                  Commodities
                </div>
              </td>
              <td className="percentInput">
                <div>
                  <CurrencyInput 
                    value={commoditiesPercent}
                    placeholder="20%"
                    allowDecimals={false}
                    suffix={"%"}
                    onValueChange={(value) => setCommoditiesPercent(value)}
                    step={1}
                    className={"form-control form-control-sm text-center"}
                  />
                </div>
              </td>
              <td>
                <div>
                  <CurrencyInput 
                    value={commoditiesAllocation}
                    placeholder="$1,000"
                    allowDecimals={false}
                    prefix={"$"}
                    onValueChange={(value) => setCommoditiesAllocation(value)}
                    step={100}
                    className={"form-control form-control-sm text-center"}
                    disabled={cAllocStatus}
                  />
                </div>					
              </td>
              <td className="allocation">
                <div data-commoditiesAllocation>
                  {formatValue({ suffix: "%", decimalScale: 0, value: String(commoditiesNewPercent*100) })}
                </div>
              </td>
              <td>
                <div data-commoditiesBuySell>
                  {formatValue({ prefix: "$", decimalScale: 0, value: String(commoditiesBuySell) })}
                </div>
              </td>
              <td>
                <div data-commoditiesNewTotal>
                  {formatValue({ prefix: "$", decimalScale: 0, value: String(commoditiesNewTotal) })}
                </div>
              </td>
            </tr>


{/* //RENDER SUB ROWS */}

            {renderRows('commoditiesRow')}

{/* //OVERLAY ERROR FLAG FOR IF VALUES ARE GREATER THAN 100 */}

<td className="totalPercent" ref={targetSubCommodities} >
                <Overlay target={targetSubCommodities.current} show={isSubCommoditiesOneHundered} placement="right">
                  {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                  }) => (
                    <div
                      {...props}
                      style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(255, 100, 100, 0.85)',
                        padding: '2px 10px',
                        color: 'white',
                        borderRadius: 3,
                        ...props.style,
                      }}
                    >
                      Funds that are part of the Commodities basket shouldn't total more than 100%
                    </div>
                  )}
                </Overlay>
              </td>



      {/* CASH */}

            <tr className="align-middle">
              <td className="cashName">
                Cash
              </td>
              <td className="percentInput">
                <div>
                  <CurrencyInput 
                    value={cashPercent}
                    placeholder="20%"
                    allowDecimals={false}
                    suffix={"%"}
                    onValueChange={(value) => setCashPercent(value)}
                    step={1}
                    className={"form-control form-control-sm text-center"}
                  />
                </div>
              </td>
              <td>
                <div>
                  <CurrencyInput 
                    value={cashAllocation}
                    placeholder="$1,000"
                    allowDecimals={false}
                    prefix={"$"}
                    onValueChange={(value) => setCashAllocation(value)}
                    step={100}
                    className={"form-control form-control-sm text-center"}
                  />
                </div>					
              </td>
              <td className="allocation">
                <div data-cashAllocation>
                  {formatValue({ suffix: "%", decimalScale: 0, value: String(cashNewPercent*100) })}
                </div>
              </td>
              <td>
                <div data-cashBuySell>
                  {formatValue({ prefix: "$", decimalScale: 0, value: String(cashBuySell) })}
                </div>
              </td>
              <td>
                <div data-cashNewTotal>
                  {formatValue({ prefix: "$", decimalScale: 0, value: String(cashNewTotal) })}
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot className="total">
            <tr>
              <td>
                Total:
              </td>
              <td className="totalPercent" ref={targetTotal} style={percentStyle}>
                {totalPercent}
                <Overlay target={targetTotal.current} show={isOneHundered} placement="bottom">
                  {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                  }) => (
                    <div
                      {...props}
                      style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(255, 100, 100, 0.85)',
                        padding: '2px 10px',
                        color: 'white',
                        borderRadius: 3,
                        ...props.style,
                      }}
                    >
                      Total Percent Must Equal 100
                    </div>
                  )}
                </Overlay>
              </td>
              <td>
                <div data-totalAllocation className="newTotal">
                  {formatValue({ prefix: "$", decimalScale: 0, value: String(totalAllocation) })}
                </div>
              </td>
              <td className="allocation">
              </td>
              <td>
              </td>
              <td>
                <div data-grandTotal className="newTotal">
                  {formatValue({ prefix: "$", decimalScale: 0, value: String(parseFloat(totalAllocation) + parseFloat(newMoney)) })}
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
  	</div>
  );
}

export default Home;


