import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './style.css';
import siteLogo from "./img/assetCalcLogoAlt.png"

export default function NavBar() {
  return (
    <Navbar className="navBar" expand="lg"  variant="dark">
      <Container>
        <Navbar.Brand href="/" className="fs-2">
          <img src={siteLogo} alt= "four diamonds that represent the logo for the AssetCalculators.com" className= "siteLogo"/>
          Asset Calculators
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <NavDropdown title="Calculators" id="basic-nav-dropdown"  className="">
            <NavDropdown.Item href="/">Portfolio Rebalancer </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

